@import "../variables";

.chip {
	white-space: nowrap;
	padding: 4px 8px;
	background-color: $n150;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: $borderRadiusSmall;
	flex: none;
	order: 0;
	flex-grow: 0;
	&__icon {
		display: flex;
		align-items: center;
		svg * {
			fill: $n300;
		}
	}

	&--deletable,
	&__label {
		cursor: pointer;
	}

	&__delete-icon {
		cursor: pointer;
		svg {
			height: 10px;
			width: 10px;
		}
	}

	&--active {
		background-color: $n500;
		color: $n000;
		svg * {
			fill: $n000;
		}
	}
}

.standardized-form {
	.chip__root {
		@extend .chip;

		&--icon {
			padding: 1px 4px;
		}
		padding-right: 0;
		height: unset;
		line-height: 1;
	}

	.chip__root--autocomplete {
		margin: $xxs !important;
	}

	.chip__label {
		padding: 0;
	}
}
