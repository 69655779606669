@import "../../../../../../assets/styles/variables";

.Lexical-content {
	&--594 {
		height: calc(100vh - 594px);
		max-height: calc(100vh - 594px);
	}
	&--485 {
		height: calc(100vh - 485px);
		max-height: calc(100vh - 485px);
	}
	&--515 {
		height: calc(100vh - 515px);
		max-height: calc(100vh - 515px);
	}
}
.Placeholder__root {
	font-size: 14px;
	color: #999;
	overflow: hidden;
	position: absolute;
	text-overflow: ellipsis;
	top: 8px;
	left: 16px;
	right: 16px;
	user-select: none;
	white-space: nowrap;
	display: inline-block;
	pointer-events: none;
}
.ContentEditable__root {
	border: 0;
	font-size: 15px;
	display: block;
	position: relative;
	tab-size: 1;
	outline: 0;
	padding: 8px 16px;
	min-height: calc(100% - 16px);
	background-color: transparent;
	&.p-0 {
		padding: 0px;
	}
}

.LexicalEditor__editor-scroller {
	min-height: 150px;
	border: 0;
	resize: none;
	cursor: text;
	display: block;
	position: relative;
	outline: 0;
	overflow: auto;
	resize: vertical;
	background-color: white;
}

.LexicalEditor__editor {
	height: 100%;
	position: relative;
}

i {
	&.bold {
		background-image: url(../images/type-bold.svg);
	}
	&.italic {
		background-image: url(../images/type-italic.svg);
	}
	&.clear {
		background-image: url(../images/trash.svg);
	}
	&.code {
		background-image: url(../images/code.svg);
	}
	&.underline {
		background-image: url(../images/type-underline.svg);
	}
	&.strikethrough {
		background-image: url(../images/type-strikethrough.svg);
	}
	&.subscript {
		background-image: url(../images/type-subscript.svg);
	}
	&.superscript {
		background-image: url(../images/type-superscript.svg);
	}
	&.link {
		background-image: url(../images/link.svg);
	}
	&.horizontal-rule {
		background-image: url(../images/horizontal-rule.svg);
	}
	&.image {
		background-image: url(../images/file-image.svg);
	}
	&.table {
		background-image: url(../images/table.svg);
	}
	&.close {
		background-image: url(../images/close.svg);
	}
	&.left-align {
		background-image: url(../images/text-left.svg);
	}
	&.center-align {
		background-image: url(../images/text-center.svg);
	}
	&.right-align {
		background-image: url(../images/text-right.svg);
	}
	&.justify-align {
		background-image: url(../images/justify.svg);
	}
	&.indent {
		background-image: url(../images/indent.svg);
	}
	&.markdown {
		background-image: url(../images/markdown.svg);
	}

	&.outdent {
		background-image: url(../images/outdent.svg);
	}
	&.undo {
		background-image: url(../images/arrow-counterclockwise.svg);
	}

	&.redo {
		background-image: url(../images/arrow-clockwise.svg);
	}

	&.sticky {
		background-image: url(../images/sticky.svg);
	}

	&.mic {
		background-image: url(../images/mic.svg);
	}

	&.import {
		background-image: url(../images/upload.svg);
	}

	&.export {
		background-image: url(../images/download.svg);
	}

	&.diagram-2 {
		background-image: url(../images/diagram-2.svg);
	}

	&.user {
		background-image: url(../images/user.svg);
	}

	&.equation {
		background-image: url(../images/plus-slash-minus.svg);
	}

	&.gif {
		background-image: url(../images/filetype-gif.svg);
	}

	&.copy {
		background-image: url(../images/copy.svg);
	}

	&.success {
		background-image: url(../images/success.svg);
	}
}

#block-controls {
	display: block;
	position: absolute;
	right: 10px;
	width: 32px;
	height: 32px;
	box-sizing: border-box;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
	top: 16px;
	z-index: 10;
	border-radius: 8px;
	border: 1px solid rgb(206, 208, 212);
	overflow: hidden;
	button {
		border: 1px solid white;
		background-color: #fff;
		display: block;
		transition: background-color 0.1s ease;
		cursor: pointer;
		outline: none;
		border-radius: 8px;
		padding: 3px;

		&:hover {
			background-color: #efefef;
		}
		&:focus-visible {
			border-color: blue;
		}
	}
	span {
		.block-type {
			background-size: contain;
			display: block;
			width: 18px;
			height: 18px;
			margin: 2px;
			&.paragraph {
				background-image: url(../images/text-paragraph.svg);
			}
			&.h1 {
				background-image: url(../images/type-h1.svg);
			}
			&.h2 {
				background-image: url(../images/type-h2.svg);
			}
			&.quote {
				background-image: url(../images/chat-square-quote.svg);
			}
			&.ul {
				background-image: url(../images/list-ul.svg);
			}
			&.ol {
				background-image: url(../images/list-ol.svg);
			}
			&.code {
				background-image: url(../images/code.svg);
			}
		}
	}
}

.LexicalEditor__dropdown {
	z-index: 10;
	display: block;
	position: fixed;
	box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
		inset 0 0 0 1px rgba(255, 255, 255, 0.5);
	border-radius: 8px;
	min-height: 40px;
	background-color: #fff;
	&.pos-abs {
		position: absolute;
	}
	.item {
		margin: 0 8px 0 8px;
		padding: 8px;
		color: #050505;
		cursor: pointer;
		line-height: 16px;
		font-size: 15px;
		display: flex;
		align-content: center;
		flex-direction: row;
		flex-shrink: 0;
		justify-content: space-between;
		background-color: #fff;
		border-radius: 8px;
		border: 0;
		max-width: 250px;
		min-width: 100px;
		.fontsize-item {
			.text {
				min-width: unset;
			}
		}
		.active {
			display: flex;
			width: 20px;
			height: 20px;
			background-size: contain;
		}
		.LexicalEditor__icon {
			display: flex;
			width: 20px;
			height: 20px;
			user-select: none;
			margin-right: 12px;
			line-height: 16px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
		.text {
			display: flex;
			line-height: 20px;
			flex-grow: 1;
			min-width: 150px;
		}
		&:first-child {
			margin-top: 8px;
		}
		&:last-child {
			margin-bottom: 8px;
		}
		&:hover {
			background-color: #eee;
		}
	}
}

.LexicalEditor__icon {
	&.paragraph {
		background-image: url(../images/text-paragraph.svg);
	}
	&.h1 {
		background-image: url(../images/type-h1.svg);
	}
	&.h2 {
		background-image: url(../images/type-h2.svg);
	}
	&.h3 {
		background-image: url(../images/type-h3.svg);
	}
	&.h4 {
		background-image: url(../images/type-h4.svg);
	}
	&.h5 {
		background-image: url(../images/type-h5.svg);
	}
	&.h6 {
		background-image: url(../images/type-h6.svg);
	}
	&.check-list,
	&.check {
		background-image: url(../images/square-check.svg);
	}
	&.bullet-list,
	&.bullet {
		background-image: url(../images/list-ul.svg);
	}
	&.numbered-list,
	&.number {
		background-image: url(../images/list-ol.svg);
	}
	&.quote {
		background-image: url(../images/chat-square-quote.svg);
	}

	&.code {
		background-image: url(../images/code.svg);
	}
	&.plus {
		background-image: url(../images/plus.svg);
	}

	&.caret-right {
		background-image: url(../images/caret-right-fill.svg);
	}

	&.dropdown-more {
		background-image: url(../images/dropdown-more.svg);
	}

	&.font-color {
		background-image: url(../images/font-color.svg);
	}

	&.font-family {
		background-image: url(../images/font-family.svg);
	}

	&.bg-color {
		background-image: url(../images/bg-color.svg);
	}
	&.left-align {
		background-image: url(../images/text-left.svg);
	}
}

@media screen and (max-width: 1100px) {
	.dropdown-button-text {
		display: none !important;
	}
	.font-size .dropdown-button-text {
		display: flex !important;
	}
	.code-language .dropdown-button-text {
		display: flex !important;
	}
}

.LexicalEditor-shell {
	border-radius: 2px;
	color: #000;
	position: relative;
	line-height: 1.7;
	font-weight: 400;

	span.editor-image {
		cursor: default;
		display: inline-block;
		position: relative;
		user-select: none;
	}
	.editor-container {
		background: transparent;
		position: relative;
		cursor: text;
		display: block;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.editor-image {
		video,
		img {
			max-width: 100%;
			cursor: default;
			&.focused {
				outline: 2px solid rgb(60, 132, 244);
				user-select: none;
				&.draggable {
					cursor: grab;
					&:active {
						cursor: grabbing;
					}
				}
			}
		}

		.image-caption-container {
			display: block;
			position: absolute;
			bottom: 4px;
			left: 0;
			right: 0;
			padding: 0;
			margin: 0;
			border-top: 1px solid #fff;
			background-color: rgba(255, 255, 255, 0.9);
			min-width: 100px;
			color: #000;
			overflow: hidden;
		}

		.image-caption-button {
			display: block;
			position: absolute;
			bottom: 20px;
			left: 0;
			right: 0;
			width: 30%;
			padding: 10px;
			margin: 0 auto;
			border: 1px solid rgba(255, 255, 255, 0.3);
			border-radius: 5px;
			background-color: rgba(0, 0, 0, 0.5);
			min-width: 100px;
			color: #fff;
			cursor: pointer;
			user-select: none;
			&:hover {
				background-color: rgba(60, 132, 244, 0.5);
			}
		}
		.image-resizer {
			display: block;
			width: 7px;
			height: 7px;
			position: absolute;
			background-color: rgb(60, 132, 244);
			border: 1px solid #fff;
			&-n {
				top: -6px;
				left: 48%;
				cursor: n-resize;
			}
			&-ne {
				top: -6px;
				right: -6px;
				cursor: ne-resize;
			}
			&-e {
				bottom: 48%;
				right: -6px;
				cursor: e-resize;
			}
			&-se {
				bottom: -2px;
				right: -6px;
				cursor: nwse-resize;
			}
			&-s {
				bottom: -2px;
				left: 48%;
				cursor: s-resize;
			}
			&-sw {
				bottom: -2px;
				left: -6px;
				cursor: sw-resize;
			}
			&-w {
				bottom: 48%;
				left: -6px;
				cursor: w-resize;
			}
			&-nw {
				top: -6px;
				left: -6px;
				cursor: nw-resize;
			}
		}
	}
}

.table-cell-action-button-container {
	position: absolute;
	top: 0;
	left: 0;
	will-change: transform;
}

.table-cell-action-button {
	background-color: none;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
	position: relative;
	border-radius: 15px;
	color: #222;
	display: inline-block;
	cursor: pointer;
}

i.chevron-down {
	background-color: transparent;
	background-size: contain;
	display: inline-block;
	height: 8px;
	width: 8px;
	background-image: url(../images/chevron-down.svg);
}

.ltr {
	text-align: left;
}

.rtl {
	text-align: right;
}

.LexicalEditor__toolbar {
	display: flex;
	padding: 4px;
	vertical-align: middle;
	overflow: auto;
	height: 36px;
	button {
		&.toolbar-item {
			border: 0;
			display: flex;
			background: none;
			border-radius: 10px;
			padding: 8px;
			cursor: pointer;
			vertical-align: middle;
			flex-shrink: 0;
			align-items: center;
			justify-content: space-between;
			&:disabled {
				cursor: not-allowed;
			}
			&.spaced {
				margin-right: 2px;
			}
			i.format {
				background-size: contain;
				display: inline-block;
				height: 18px;
				width: 18px;
				vertical-align: -0.25em;
				display: flex;
				opacity: 0.6;
			}
			&.active {
				background-color: rgba(223, 232, 250, 0.3);
				i {
					opacity: 1;
				}
			}
		}
	}
	.toolbar-item {
		.text {
			display: flex;
			line-height: 20px;
			vertical-align: middle;
			font-size: 14px;
			color: #777;
			text-overflow: ellipsis;
			overflow: hidden;
			height: 20px;
			text-align: left;
			padding-right: 10px;
		}
		.LexicalEditor__icon {
			display: flex;
			width: 20px;
			height: 20px;
			user-select: none;
			margin-right: 8px;
			line-height: 16px;
			background-size: contain;
		}
	}
	i {
		&.chevron-down {
			margin-top: 3px;
			width: 16px;
			height: 16px;
			display: flex;
			user-select: none;
		}
		&.chevron-down.inside {
			width: 16px;
			height: 16px;
			display: flex;
			margin-left: -25px;
			margin-top: 11px;
			margin-right: 10px;
			pointer-events: none;
		}
	}
	.divider {
		width: 1px;
		background-color: $n150;
		margin: 0 4px;
	}
}

.LexicalEditor__toolbar button.toolbar-item:disabled .LexicalEditor__icon,
.LexicalEditor__toolbar button.toolbar-item:disabled .text,
.LexicalEditor__toolbar button.toolbar-item:disabled i.format,
.LexicalEditor__toolbar button.toolbar-item:disabled .chevron-down {
	opacity: 0.2;
}

.LexicalEditor__toolbar .toolbar-item:hover:not([disabled]) {
	background-color: #eee;
}

button.item i {
	opacity: 0.6;
}

button.item.dropdown-item-active {
	background-color: rgba(223, 232, 250, 0.3);
}

button.item.dropdown-item-active i {
	opacity: 1;
}

hr {
	padding: 2px 2px;
	border: none;
	margin: 1em 0;
	cursor: pointer;
}

hr:after {
	content: "";
	display: block;
	height: 2px;
	background-color: #ccc;
	line-height: 2px;
}

hr.selected {
	outline: 2px solid rgb(60, 132, 244);
	user-select: none;
}

.TableNode__contentEditable {
	min-height: 20px;
	border: 0px;
	resize: none;
	cursor: text;
	display: block;
	position: relative;
	tab-size: 1;
	outline: 0px;
	padding: 0;
	user-select: text;
	font-size: 15px;
	white-space: pre-wrap;
	word-break: break-word;
	z-index: 3;
}

.draggable-block-menu {
	border-radius: 4px;
	padding: 2px 1px;
	cursor: grab;
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	will-change: transform;
}

.draggable-block-menu .LexicalEditor__icon {
	width: 16px;
	height: 16px;
	opacity: 0.3;
	background-image: url(../images/draggable-block-menu.svg);
}

.draggable-block-menu:active {
	cursor: grabbing;
}

.draggable-block-menu:hover {
	background-color: #efefef;
}

.draggable-block-target-line {
	pointer-events: none;
	background: deepskyblue;
	height: 4px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	will-change: transform;
}
.TableCellResizer__resizer {
	position: absolute;
}
