/**Variables */
@import "./_fonts";
/**COLORS */
// Start: Old color variables, should be moved out of use
$white: #ffffff;
$black: #000000;
$grey: #dcdcdc;
$greySuperDark: #3b3b3b;
$greyDark: #5e5e5e;
$greyDarkSecondary: #a6a6a6;
$greyMedium: #7b7b7b;
$greyLight: #e6e6e6;
$greySuperLight: #d0d0d0;
$brownSuperLight: #feeacc;
$orangeLightTransparent: #ffcf674d;
$orangeBoldTransparent: #f7940033;
$orangeTransparent: #663e001a;
$projectGreyLight: #d0d0d0;
$projectBlue: #559ed5;
$silver: #c3c3c3;
$gainsboro: #dddddd;
$gainsboroLight: #e0e0e0;
$greyWhisper: #ededed;
$pinkSwan: #b8b8b8;
$pinkSwanLightest: #b2b2b2;
$whiteSmoke: #f2f2f2;
$whiteSmokeTransparent: #f2f2f2d6;
$buildingWhiteSmoke: #f8f8f8;
$projectInactiveMediumGrey: #a5a5a5;
$trashedGrey: #888888;
$oldLace: #fff4e5;
$aliceBlue: #f5f6f7;
$horsesNeckTransparent: #66523380;
$snow: #fafafa;
$tangerine: #f37e00;
$tangerineTransparent: #f57e001a;
$eclipse: #404040;
$linen: #fef2e5;
$materialDefaultLabelColor: #0000008a;
$inactive: #fafcfd;
// END: Old color variables, should be moved out of use

$rowHover: rgba(0, 0, 0, 0.02);
$n000: #fff;
$n050: #fafcfd;
$n100: #f4f8fa;
$n150: #e8ebed;
$n200: #c5cdd3;
$n300: #7f909f;
$n301: #8593a5;
$n350: #667991;
$n400: #46535d;
$n500: #1b2024;

$p300: #ffc670;

$orange: #f79400;
$orangeDark: #e08600;
$orangeDarker: #b86e00;
$orangeLight: #ffc670;
$orangeLighter: #fff7eb;

$blue: #3198ed;
$blueDark: #2877b8;
$blueLight: #ecf6fd;

$red: #e93547;
$redDark: #ca1628;
$redLight: #fdedee;

$green: #48bf92;
$greenDark: #359772;
$greenLight: #f0faf6;

$yellow: #f7cf00;
$yellowDark: #b89900;
$yellowLight: #fff4b8;

$primary: $orange;
$primarySelected: rgba(255, 198, 112, 0.16);
$primaryDark: $orangeDark;
$primaryDarker: $orangeDarker;
$primaryLight: $orangeLight;
$primaryLighter: $orangeLighter;

$info: $blue;
$infoDark: $blueDark;
$infoLight: $blueLight;

$error: $red;
$errorDark: $redDark;
$errorLight: $redLight;

$warning: $yellow;
$warningDark: $yellowDark;
$warningLight: $yellowLight;

$success: $green;
$successDark: $greenDark;
$successLight: $greenLight;

$gradientBackground: radial-gradient(
	circle,
	rgba(255, 255, 255, 1) 0%,
	rgba(235, 235, 235, 1) 100%
);

$mainBoxShadow: 0px 0px 20px #0000000d;

/**FONTS */
//$montHeavy: "MontHeavy", sans-serif;
//$montBold: "MontBold", sans-serif;
$openSansRegular: "OpenSansRegular", sans-serif;
//$openSansLight: "OpenSansLight", sans-serif;
$openSansBold: "OpenSansBold", sans-serif;
//$merriweatherBold: "MerriweatherBold", serif;

$interRegular: "InterRegular", sans-serif;
$interMedium: "InterMedium", sans-serif;
$interSemibold: "InterSemibold", sans-serif;
$interBold: "InterBold", sans-serif;
$interItalic: "InterItalic", sans-serif;

/** FIXED WIDTHS/HEIGHTS */

$sidebarWidth: 80px;
$topbarHeight: 60px;
$projectInfoBarHeight: 52px;
$verticalOutletPadding: 80px;

$regular: 16px;
$m: 12px;
$s: 8px;
$xs: 4px;
$xxs: 2px;

/**RESPONSIVE BREAKPOINTS */

$breakpoints: (
	"phone": 320px,
	"md-phone": 375px,
	"lg-phone": 416px,
	"phablet": 576px,
	"tablet": 768px,
	"tablet-wide": 1024px,
	"1110px": 1110px,
	"desktop": 1200px,
	"1550px": 1550px,
	"1600": 1600px,
);

@mixin mq($width, $type: min) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);
		@if $type == max {
			$width: $width - 1px;
		}
		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	}
}

$borderRadiusRegular: 6px;
$borderRadiusSmall: 3px;
$borderRadiusLarge: 100px;

$boxShadow2: 0px 6px 8px -4px transparentize($n300, 0.84);
$boxShadow3: 0px 6px 8px -4px transparentize($n500, 0.84);
$boxShadow4: 0px 6px 8px -4px transparentize($n500, 0.76);
$boxShadow5: 0px 6px 8px -4px transparentize($n500, 0.6);

$border1: 1px solid $n150;

$transition1: 0.2s ease;

$tooltipArrowSize: 5px;
$tooltipMargin: 20px;
