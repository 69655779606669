@import "./variables";
@import "./animations";

*,
::after,
::before {
	box-sizing: border-box;
}

* {
	outline-color: $primaryLight;
}

html {
	scroll-behavior: smooth;
}

html,
body {
	margin: 0;
	padding: 0;
	font-family: $interRegular;
	background-color: $n100;
}

body {
	overflow: auto;
	&.modal-visible {
		overflow: hidden;
		.embed-model,
		.embed-model * {
			visibility: hidden;
		}
	}
}

input,
path,
button,
:before,
:after,
div,
span,
a,
:hover {
	-webkit-transition: all 0.25s cubic-bezier(0.46, 0.82, 0.61, 0.87);
	transition: all 0.25s cubic-bezier(0.46, 0.82, 0.61, 0.87);
}

.bimify-mp {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 99;
}

.mp__overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 99;
}

.mp-wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
	.mp__content {
		position: relative;
		box-shadow: $mainBoxShadow;
		border-radius: $borderRadiusSmall;
		background: white;
		animation: fade-in 0.25s;
	}
	.modal {
		max-width: 100%;
		width: 540px;
		max-height: 90vh;
		overflow: auto;
		&--medium {
			width: 720px;
		}
		&--large {
			width: 840px;
		}
		&--x-large {
			width: 1000px;
		}
	}

	.mp__close {
		width: 10px;
		display: block;
		border: none;
		height: 10px;
		background: url(../images/cmodal-close-icon.svg) center no-repeat;
		background-size: contain;
		cursor: pointer;
		z-index: 20;

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
}

.popup {
	width: 446px;
	padding: 20px 70px 30px 70px;
	.btn {
		margin: 1rem auto 0;
	}
	p {
		font-size: 1rem;
		color: black;
		text-align: center;
	}
	h6 {
		font-size: 1rem;
		text-align: center;
		color: black;
		margin-bottom: 1rem;
		margin-top: 1rem;
	}
	i {
		width: 30px;
		height: 30px;
		display: block;
		margin: auto;
	}
	&.popup--error {
		i {
			background: url(../images/error-icon.svg) center no-repeat;
			background-size: auto;
		}
	}
	&.popup--rejected {
		i {
			background: url(../images/rejected-icon.svg) center no-repeat;
			background-size: auto;
		}
	}
	&.popup--success {
		i {
			background: url(../images/success-icon.svg) center no-repeat;
			background-size: auto;
		}
	}
	&.popup--archived {
		i {
			background: url(../images/archive-icon.svg) center no-repeat;
			background-size: contain;
			filter: grayscale(1);
			opacity: 0.4;
		}
	}
	&.popup--activated {
		i {
			background: url(../images/active-icon.svg) center no-repeat;
			background-size: contain;
		}
	}
	&.popup--restore {
		i {
			background: url(../images/projects/restore-trash-green.svg) center
				no-repeat;
			background-size: contain;
		}
	}
	&.popup--trashed {
		i {
			background: url(../images/projects/trash-can-red.svg) center no-repeat;
			background-size: contain;
		}
	}
}

.modal {
	&.modal--trashed {
		width: 580px;
		text-align: center;
		padding: 30px 70px 30px 70px;
		h6 {
			font-size: 1rem;
			text-align: center;
			color: black;
			margin-bottom: 1rem;
			margin-top: 1rem;
		}
		p {
			padding-left: 50px;
			padding-right: 50px;
		}
		.button-area {
			margin: 2rem auto 0;
			.btn {
				width: 48%;
			}
		}
	}
	&.modal--archive {
		text-align: center;
		padding: 30px 70px 30px 70px;
		h6 {
			font-size: 1rem;
			text-align: center;
			color: black;
			margin-bottom: 1rem;
			margin-top: 1rem;
		}
		p {
			padding-left: 50px;
			padding-right: 50px;
		}
		.button-area {
			margin: 2rem auto 0;
			.btn {
				width: 48%;
			}
		}
	}
}

// .title {
// 	color: black;
// 	font-family: $montHeavy;
// 	margin: 0;
// 	&.title--list {
// 		font-size: 1.6rem;
// 	}
// 	.c-orange {
// 		color: $primary;
// 	}
// }

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f2f2f2;
	border-radius: $borderRadiusRegular;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #d0d0d0;
	border-radius: $borderRadiusRegular;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #b5b5b5;
}

@keyframes fade-in {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

.color-development {
	color: $primary;
}

.color-request {
	color: $greySuperLight;
}

.color-rejected {
	color: $red;
}

.color-approve {
	color: $primaryLight;
}

.color-completed,
.color-restored {
	color: $green;
}

.color-trashed {
	color: $trashedGrey;
}

.color-review {
	color: $projectBlue;
}

.background-development {
	background-color: $primary;
}

.background-request {
	background-color: $greySuperLight;
}

.background-rejected {
	background-color: $red;
}

.background-approve {
	background-color: $primaryLight;
}

.background-completed,
.background-restored {
	background-color: $green;
}

.background-trashed {
	background-color: $trashedGrey;
}

.background-review {
	background-color: $projectBlue;
}

.list-unstyled {
	list-style: none;
}

.MuiAutocomplete-popupIndicator {
	margin-right: -15px !important;
}

.MuiSnackbar-root {
	svg {
		color: $n000;
	}
	.MuiAlert-message {
		font-family: $openSansRegular;
	}
	.MuiAlert-standardError {
		background-color: $redDark;
		color: $n000;
	}
	.MuiAlert-standardSuccess {
		background-color: $greenDark;
		color: $n000;
	}
}

.box-shadow {
	border-radius: $borderRadiusRegular;
	&--orange {
		box-shadow: 12px 7px 30px $orangeTransparent;
	}
}

.notification {
	&--active {
		.icon {
			svg * .background-fill {
				fill: $primary;
				stroke: $primary;
			}
			svg * .target-fill {
				fill: $white;
			}
			svg * .target-stroke {
				stroke: $white;
			}
		}
		&:hover {
			.icon {
				svg * .background-fill {
					fill: $primaryLight;
					stroke: $primaryLight;
				}
				svg * .target-fill {
					fill: $white;
				}
				svg * .target-stroke {
					stroke: $white;
				}
			}
		}
	}
	&--animated {
		.icon__wrapper {
			box-shadow: 0 0 0 $primary;
			animation: pulse 2s infinite;
			border-radius: $borderRadiusSmall;
		}
	}
}

.font-size {
	&--1 {
		font-size: 1rem;
	}
}

.font--italic {
	font-family: $interItalic;
}

.vertical-separator--n150 {
	width: 1px;
	height: 1rem;
	background-color: $n150;
	margin: 0 12px;
}

.user {
	&__logo {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-size: 85% auto;
		background-position: center;
		background-repeat: no-repeat;
		display: flex;
		color: $n500;
		align-items: center;
		justify-content: center;
		&--initials {
			text-align: center;
			background-color: $n150;
			font-size: 0.75rem;
		}
		&--cover {
			background-size: cover;
		}
	}
}

.accordion {
	&.bg-n100 {
		@extend .bg-n100;
	}
	&.bg-primary--lighter {
		@extend .bg-primary--lighter;
	}
	&.bg-error--light {
		@extend .bg-error--light !optional;
	}
	&.bg-success--light {
		@extend .bg-success--light !optional;
	}
}

.alert {
	border-radius: $borderRadiusRegular;
	border: 1px solid;
	&--success {
		background-color: $successLight !important;
		border-color: $successDark;
		.title,
		.close svg * {
			color: $successDark;
		}
	}
	&--info {
		background-color: $infoLight !important;
		border-color: $infoDark;
		.title,
		.close svg * {
			color: $successDark;
		}
	}
	&--warning {
		background-color: $warningLight !important;
		border-color: $warningDark;
		.title,
		.close svg * {
			color: $warningDark;
		}
	}
	&--error {
		background-color: $errorLight !important;
		border-color: $errorDark;
		.title,
		.close svg * {
			color: $errorDark;
		}
	}

	&__icon {
		display: flex;
		align-items: flex-start;
	}
}

.border-radius {
	&--small {
		border-radius: $borderRadiusSmall;
	}
	&--regular {
		border-radius: $borderRadiusRegular;
	}
	&--large {
		border-radius: $borderRadiusLarge;
	}
}
.MuiSkeleton-root {
	background-color: $n100 !important;
}
