/**Custom grid layout */

.container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.row {
	display: flex;
	flex-wrap: wrap;
	width: calc(100% + (#{$regular} * 2));
	margin-left: -$regular;
	margin-right: -$regular;

	.col {
		display: flex;
		padding-left: $regular;
		padding-right: $regular;
		&--initial {
			flex: initial;
		}
	}

	$widths: (
		"m": $m,
		"s": $s,
		"6p": 6px,
		"xs": $xs,
	);

	@each $label, $width in $widths {
		&--#{$label}-gutters {
			width: calc(100% + (#{$width} * 2));
			margin-left: -$width;
			margin-right: -$width;
			& > .col {
				padding-left: $width;
				padding-right: $width;
			}
		}
	}

	&--no-gutters {
		width: calc(100% + (#{$s} * 2));
		margin-left: 0;
		margin-right: 0;
		& > .col {
			&.pl-m {
				padding-left: 12px;
			}
			&.pl-0 {
				padding-left: 0;
			}
			&.pr-0 {
				padding-right: 0;
			}
		}
	}
}

$colWidths: (
	"5": 5%,
	"8": 8%,
	"9": 9%,
	"10": 10%,
	"12": 12%,
	"12-5": 12.5%,
	"14": 14%,
	"13": 13%,
	"15": 15%,
	"16": 16%,
	"16-6": 16.66%,
	"17": 17%,
	"17-5": 17.5%,
	"18": 18%,
	"20": 20%,
	"22": 22%,
	"23": 23.33%,
	"25": 25%,
	"25-5": 25.5%,
	"26": 26%,
	"27": 27%,
	"28": 28%,
	"30": 30%,
	"33": 33.33333333%,
	"34": 34%,
	"35": 35%,
	"38": 38%,
	"40": 40%,
	"45": 45%,
	"48": 48%,
	"50": 50%,
	"55": 55%,
	"60": 60%,
	"66": 66.66666666%,
	"67": 67%,
	"65": 65%,
	"70": 70%,
	"75": 75%,
	"80": 80%,
	"85": 85%,
	"87-5": 87.5%,
	"88": 88%,
	"90": 90%,
	"95": 95%,
	"100": 100%,
	"auto": auto,
);

.col {
	flex: 1 0 0%;
}

@each $label, $value in $colWidths {
	.col-auto {
		flex: 0 0 auto;
	}

	.flex-#{$label},
	.col-#{$label} {
		width: $value;
		@if $value == "100%" {
			flex: 0 0 100%;
		} @else {
			flex: 0 0 auto;
		}
	}

	.w-#{$label} {
		width: $value;
	}
}

@each $label, $value in $colWidths {
	@include mq("tablet") {
		.col-tablet-auto {
			flex: 0 0 auto;
		}

		.flex-tablet-#{$label},
		.col-tablet-#{$label} {
			width: $value;
			@if $value == "100%" {
				flex: 0 0 100%;
			} @else {
				flex: 0 0 auto;
			}
		}

		.w-tablet-#{$label} {
			width: $value;
		}
	}
}

@each $label, $value in $colWidths {
	@include mq("tablet-wide") {
		.col-tablet-wide-auto {
			flex: 0 0 auto;
		}

		.flex-tablet-wide-#{$label},
		.col-tablet-wide-#{$label} {
			width: $value;
			@if $value == "100%" {
				flex: 0 0 100%;
			} @else {
				flex: 0 0 auto;
			}
		}

		.w-tablet-wide-#{$label} {
			width: $value;
		}
	}
}

@each $label, $value in $colWidths {
	@include mq("1110px") {
		.col-1110px-auto {
			flex: 0 0 auto;
		}

		.flex-1110px-#{$label},
		.col-1110px-#{$label} {
			width: $value;
			@if $value == "100%" {
				flex: 0 0 100%;
			} @else {
				flex: 0 0 auto !important;
			}
		}

		.w-1110px-#{$label} {
			width: $value;
		}
	}
}

@each $label, $value in $colWidths {
	@include mq("desktop") {
		.col-desktop-auto {
			flex: 0 0 auto;
		}

		.flex-desktop-#{$label},
		.col-desktop-#{$label} {
			width: $value;
			@if $value == "100%" {
				flex: 0 0 100%;
			} @else {
				flex: 0 0 auto;
			}
		}
	}
}
@each $label, $value in $colWidths {
	@include mq("1550px") {
		.col-1280px-auto {
			flex: 0 0 auto;
		}

		.flex-1550px-#{$label},
		.col-1550px-#{$label} {
			width: $value;
			@if $value == "100%" {
				flex: 0 0 100%;
			} @else {
				flex: 0 0 auto;
			}
		}

		.w-1550px-#{$label} {
			width: $value;
		}
	}
}
@each $label, $value in $colWidths {
	@include mq("1600") {
		.col-1600-auto {
			flex: 0 0 auto;
		}

		.flex-1600-#{$label},
		.col-1600-#{$label} {
			width: $value;
			@if $value == "100%" {
				flex: 0 0 100%;
			} @else {
				flex: 0 0 auto;
			}
		}

		.w-1600-#{$label} {
			width: $value;
		}
	}
}

.flex-basis-auto {
	flex-basis: auto;
}

.flex-auto {
	flex: auto;
}

.flex-0 {
	flex: 0;
}

.flex-1 {
	flex: 1;
}

.flex-2 {
	flex: 2;
}

.flex-3 {
	flex: 3;
}

.flex-4 {
	flex: 4;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.hidden {
	visibility: hidden;
}

.d-flex {
	display: flex;
}

.d-inline-flex {
	display: inline-flex;
}

.display-inline {
	display: inline;
}

.display-inline-block {
	display: inline-block;
}

.flex-column {
	flex-direction: column;
}

.flex-column-reverse {
	flex-direction: column-reverse;
}

.flex-row-reverse {
	flex-direction: row-reverse;
}

.flex-row {
	flex-direction: row;
}

@each $key, $breakpoint in $breakpoints {
	@include mq($key) {
		.d-#{$key}-flex {
			display: flex;
		}

		.d-#{$key}-inline-flex {
			display: inline-flex;
		}

		.flex-#{$key}-column {
			flex-direction: column;
		}

		.flex-#{$key}-row {
			flex-direction: row;
		}
	}
}
