@import "../../../../assets/styles/variables";

.viewer {
	padding: 40px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&.p-0 {
		padding: 0;
	}

	&--forge {
		width: 100%;
		height: 100%;
		position: relative;
		&__message {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&--file {
		width: 100%;
		height: 100%;
		position: relative;
		.lb-container {
			position: relative;
			z-index: unset;
			.lb-header {
				.close {
					display: none;
				}
			}
		}
	}
}
